import { gql } from '@/__generated__/index.ts';
import { useMutation } from '@apollo/client';
import { NotificationItemFragment } from '@/components/notifications/notification-item-fragment.tsx';

const ReadNotification = gql(`
  mutation ReadNotification($notificationId: ID!) {
    readNotification(id: $notificationId) {
      ...NotificationItemFragment
    }
  }
`);

export const useReadNotification = () => {
  const [readNotificationMutation, result] = useMutation(ReadNotification, {
    fetchPolicy: 'no-cache',
    update(cache, { data }) {
      const readNotification = data?.readNotification;
      if (readNotification) {
        cache.updateFragment(
          {
            id: cache.identify({
              __typename: 'Notification',
              id: readNotification.id,
            }),
            fragment: NotificationItemFragment,
            fragmentName: 'NotificationItemFragment',
          },
          (data) => {
            if (!data) return data;

            return {
              ...data,
              read: true,
            };
          },
        );
        cache.modify({
          fields: {
            notifications(existing, { storeFieldName, readField }) {
              if (storeFieldName.includes('"read":false')) {
                return {
                  ...existing,
                  items: existing.items.filter((itemRef: { __ref: string }) => {
                    return readField('id', itemRef) !== readNotification.id;
                  }),
                };
              } else {
                return existing;
              }
            },
          },
        });
      }
    },
  });

  const readNotification = (notificationId: string) => {
    return readNotificationMutation({
      variables: {
        notificationId,
      },
    });
  };

  return [readNotification, result] as const;
};
