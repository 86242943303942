import { gql } from '@/__generated__';

export const NotificationItemFragment = gql(`
  fragment NotificationItemFragment on Notification {
    read
    createdAt
    id
    notificationUserId
    inAppNotificationContent {
      htmlTitle
      htmlBody
      jsonBody
      image {
        alt
        href
        file {
          key
          bucket
          region
          type
        }
      }
      toggleFollowUserButton {
        user {
          id
          following
        }
      }
      bottomActions {
        title
        href
      }
    }
  }
`);
