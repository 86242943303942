import { ApolloCache, Reference, useSubscription } from '@apollo/client';
import { gql } from '@/__generated__/index.ts';
import { NotificationItemFragmentFragment } from '@/__generated__/graphql.ts';
import { NotificationItemFragment } from '@/components/notifications/notification-item-fragment.tsx';

const NotificationsFeedSubscription = gql(`
  subscription OnNotificationsUpdated {
    onNotification {
      ...NotificationItemFragment
    }
  }
`);

const writeNotificationItemFragment = (
  cache: ApolloCache<object>,
  notification: NotificationItemFragmentFragment,
) => {
  return cache.writeFragment({
    fragment: NotificationItemFragment,
    fragmentName: 'NotificationItemFragment',
    data: notification,
  });
};

const updateNotificationsPage = (
  newNotificationRef: Reference,
  existingPage: { items: Reference[] },
) => {
  const idx = existingPage.items.findIndex(
    (item: { __ref: string }) => item.__ref === newNotificationRef.__ref,
  );

  const items =
    idx === -1
      ? [newNotificationRef, ...existingPage.items]
      : existingPage.items
          .slice(0, idx)
          .concat(newNotificationRef)
          .concat(existingPage.items.slice(idx + 1));

  return {
    ...existingPage,
    items,
  };
};

export const NotificationsSubscription = ({
  onData,
}: {
  onData?: (notification: NotificationItemFragmentFragment) => void;
}) => {
  useSubscription(NotificationsFeedSubscription, {
    onData({ data, client }) {
      const notification = data.data?.onNotification;
      if (notification) {
        onData?.(notification);
        client.cache.modify({
          fields: {
            notifications(existing) {
              const newNotificationRef = writeNotificationItemFragment(
                client.cache,
                notification,
              );

              if (newNotificationRef) {
                return updateNotificationsPage(newNotificationRef, existing);
              }

              return existing;
            },
          },
        });
      }
    },
  });

  return null;
};
